@use "~@otto-ec/global-frontend-settings/scss/variables" as vars;
@use "~@otto-ec/global-frontend-settings/scss/grid" as grid;
@use "variables" as nav-vars;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;

$collapsed-height: 200px !default;

@mixin white-gradient {
  background: -webkit-linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 8%, #ffffff 78%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 8%, #ffffff 78%);
}

.nav-content {
  margin: 35px 0 50px 0;
  h2:not(.p_headline100):not(.p_headline75):not(.p_headline50):not(.p_headline25):not(.p_adviserHeadline100__headline),
  h3:not(.p_headline100):not(.p_headline75):not(.p_headline50):not(.p_headline25):not(.p_adviserHeadline100__subheadline),
  h4:not(.p_headline100):not(.p_headline75):not(.p_headline50):not(.p_headline25) {
    font-size: 16px;
    font-weight: normal;
    line-height: 20px;
  }

  h2,
  h3,
  h4 {
    margin-bottom: 10px;
  }
}

.nav-consultant-content {
  color: vars.$black100;
}

.nav-content-expander {
  position: relative;
  width: 100%;
  padding: 0 16px;
  margin: 0 0 4px 0;
  overflow: hidden;
  background-color: #fff;

  height: $collapsed-height;
  transition: height nav-vars.$easeInOutCubic;

  .p_unorderedList100 {
    margin-bottom: 0.8em;
  }

  .nav-separator {
    border-bottom: 1px solid vars.$grey200;
  }

  &.nav-expander-toggled {
    height: auto;
  }

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    height: auto;

    &.nav-expander-toggled {
      height: $collapsed-height;
    }
  }
}

.nav-expander-button {
  font-size: 14px;
  color: #008ffd;
  min-height: 48px;
  border: 0;
  background-color: #fff;
  width: 100%;
  text-align: left;
  padding-left: 16px;
  outline: none;
  cursor: pointer;
  margin: 0;                  /*                                         */
                              /*                                            */

  &:after {
    content: attr(data-expand);
    vertical-align: middle;
  }

  &:before {
    font-family: OttoIconFonts;
    font-size: 1.125rem;
    width: 24px;
    height: 24px;
    padding-right: 16px;
    content: "\e925";
    vertical-align: middle;
  }

  &.nav-expander-toggled {
    &:after {
      content: attr(data-collapse);
    }

    &:before {
      content: "\e924";
    }
  }

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    &:after {
      content: attr(data-collapse);
    }

    &:before {
      content: "\e924";
    }

    &.nav-expander-toggled {
      &:after {
        content: attr(data-expand);
      }
      &:before {
        content: "\e925";
      }
    }
  }
}

.nav-passepartout {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.nav-passepartout-fade {
  width: 100%;
  min-height: 30px;
  @include white-gradient;

  .nav-expander-toggled & {
    display: none;
  }

  @include gfs.breakpoint(gfs.$breakpoint-l) {
    display: none;

    .nav-expander-toggled & {
      display: block;
      @include white-gradient;
    }
  }
}
